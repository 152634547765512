<template>
  <div class="orderDetail">
    <div class="detail detail-box1">
      <div class="box-left">
        <div class="left-status">
          <img
            src="../../assets/image/con1.png"
            alt=""
          >
          <div v-if="status==='1'">
            待审核
          </div>
          <div v-if="status==='2'">
            审核拒绝
          </div>
          <div v-if="status === '3'">
            待支付
          </div>
          <div v-if="status === '4'">
            已支付
          </div>
          <div v-if="status === '5'">
            投保完成
          </div>
        </div>
        <div class="left-box">
          <div class="flex">
            <div class="left-msg">
              <div>业务流水号：</div>
              <div class="msg">
                XXX202203140645815423
              </div>
            </div>
            <div class="left-msg">
              <div>下单时间：</div>
              <div class="msg">
                2022-03-14 16:12:46
              </div>
            </div>
          </div>
          <div
            class="left-reason"
            v-if="status==='2'"
          >
            审核不通过：财务审计报告时间有误，请重新上传。
          </div>
        </div>
      </div>
      <div class="box-right">
        <div
          class="flex right-btn"
          v-if="status === '2'"
        >
          <a-button
            type="primary"
          >
            修改
          </a-button>
          <a-button type="primary">
            取消
          </a-button>
        </div>
        <a-button
          type="primary"
          v-if="status === '3'"
          @click="$router.push('./payIndex')"
        >
          去支付
        </a-button>
        <div
          class="flex right-btn"
          v-if="status === '4'"
        >
          <a-button
            type="primary"
          >
            下载预保函
          </a-button>
          <a-button
            type="primary"
            @click="() => (modal2Visible = true)"
          >
            退保
          </a-button>
        </div>
        <a-button
          type="primary"
          v-if="status === '5'"
        >
          下载保函
        </a-button>
      </div>
    </div>
    <div class="detail detail-box2">
      <div class="tit">
        投保进度
      </div>
      <div class="step">
        <a-steps :current="current">
          <a-step>
            <template slot="title">
              <span>提交申请</span>
            </template>
            <span slot="description">(2022-03-14 16:12:46)</span>
          </a-step>
          <a-step>
            <template slot="title">
              投保审核
            </template>
            <span slot="description">(2022-03-14 16:12:46)</span>
          </a-step>
          <a-step>
            <template slot="title">
              支付保费
            </template>
            <span slot="description">(2022-03-14 16:12:46)</span>
          </a-step>
          <a-step>
            <template slot="title">
              投保成功
            </template>
            <span slot="description">(2022-03-14 16:12:46)</span>
          </a-step>
        </a-steps>
      </div>
    </div>
    <div
      class="detail detail-box3"
    >
      <div class="top">
        <div class="tit">
          投保信息
        </div>
        <div>
          阅读
          <span>《投保须知》</span>
          <a-button
            type="link"
            v-if="status!=='4'"
            @click="$router.push('./applyinformation')"
          >
            查看申请信息
          </a-button>
        </div>
      </div>
      <div class="content">
        <a-form
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-item label="担保方：">
            <div>四川金鼎 & 上海银行</div>
          </a-form-item>
          <a-form-item label="保函号：">
            <div>RD202203141451672754</div>
          </a-form-item>
          <a-form-item label="保函类型：">
            <div>履约保函</div>
          </a-form-item>
          <a-form-item label="投保人：">
            <div>XXX企业</div>
          </a-form-item>
          <a-form-item label="手机号：">
            <div>13300000001</div>
          </a-form-item>
          <a-form-item label="保证金额：">
            <div>10,00,000元</div>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div
      class="detail detail-box4"
      v-if="status === '4'||status === '5'"
    >
      <div class="tit">
        支付信息
      </div>
      <div class="content">
        <a-form
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-item label="支付时间">
            <div>2022-03-14 16:29:19</div>
          </a-form-item>
          <a-form-item label="支付金额：">
            <div>506.27 元</div>
          </a-form-item>
          <a-form-item label="支付方式：">
            <div>线下支付</div>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div
      class="detail detail-box5"
      v-if="status === '5'"
    >
      <div class="top">
        <div class="tit">
          发票信息
        </div>
        <span style="margin-top:3px">（电子发票1~2个工作日，纸质发票3~5工作日）</span>
      </div>
      <a-button type="primary">
        申请开票
      </a-button>
    </div>
    <div
      class="detail detail-box6"
      v-if="status === '3'||status === '4'||status === '5'"
    >
      <div class="tit">
        应付保费总计<span style="color:red;margin-left:20px">506.27 元</span>
      </div>
    </div>
    <div id="components-modal-demo-position">
      <a-modal
        v-model="modal2Visible"
        title="申请退保"
        centered
        cancel-text="取消"
        ok-text="确认"
        @ok="() => (modal2Visible = false)"
      >
        <a-form
          :label-col="labelCol2"
          :wrapper-col="wrapperCol"
        >
          <a-form-item label="手机号">
            <div>13300000001</div>
          </a-form-item>
          <a-form-item label="图形验证码">
            <div style="display:flex;">
              <a-input />
              <img
                style="width: 270px;height: 32px;margin-left: 17px;margin-right: 12px"
                src="../../assets/image/con1.png"
              >
            </div>
          </a-form-item>
          <a-form-item label="短信验证码">
            <div style="display:flex;">
              <a-input />
              <a-button type="link">
                获取短信验证码
              </a-button>
            </div>
          </a-form-item>
          <a-form-item label="退保原因">
            <a-input type="textarea" />
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
      labelCol2: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      status: '',
      current: 0,
      modal2Visible: false,
    };
  },
  mounted() {
    this.status = this.$route.query.status;
    if (this.status === '1' || this.status === '2') {
      this.current = 1;
    } else if (this.status === '3' || this.status === '4') {
      this.current = 2;
    } else if (this.status === '5') {
      this.current = 4;
    }
  },
  methods: {
    setModal1Visible(modal1Visible) {
      this.modal1Visible = modal1Visible;
    },
  },
};
</script>
<style lang="less" scoped>
.orderDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1200px;
}
.flex{
  display: flex;
}
.tit {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 30px;
}
.detail {
  width: 80%;
  border: 1px solid #ebedf0;
  margin-bottom: 30px;
}
.detail-box1 {
  width: 80%;
  border: 1px solid #ebedf0;
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
  padding: 30px 60px;
  .box-left {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .left-status {
      font-size: 18px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 100px;
        height: 70px;
        margin-bottom: 10px;
      }
    }
    .left-msg {
      font-size: 16px;
      margin-left: 100px;
      margin-bottom: 20px;
      .msg {
        font-size: 18px;
        font-weight: 600;
        margin-top: 15px;
      }
    }
    .left-reason{
      margin-left: 100px;
      color: red;
    }
  }
  .box-right {
    .right-btn{
      flex-direction: column;
      ::v-deep .ant-btn{
        margin-top: 10px;
        width: 100px;
      }
    }
  }
}
.detail-box2 {
  margin: 0 0 30px 0;
  display: flex;
  flex-direction: column;
  padding: 30px 40px 40px;
  .step {
    width: 90%;
    margin: 0 auto;
  }
  ::v-deep .ant-steps-item-description {
    max-width: 162px !important;
  }
}
.detail-box3 {
  margin: 0 0 30px 0;
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  .top {
    display: flex;
    justify-content: space-between;
  }
}
.detail-box4 {
  margin: 0 0 30px 0;
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
}
.detail-box5 {
  padding: 30px 40px;
  display: flex;
  justify-content: space-between;
  .top {
    display: flex;
  }
}
.detail-box6 {
  padding: 30px 40px 0 40px;
  display: flex;
  justify-content: flex-end;
  font-size: 24px!important;
  .tit{
    font-size: 24px!important;
  }
}
</style>
